/**
 * sys_depart
 * 部门
 */

import request from '@/utils/request'

// 展示
export const sysDepartList = (data) => {
    return request({
        method: 'post',
        url: '/sys_depart/list',
        data
    })
}
// 添加
export const sysDepartAdd = (data) => {
    return request({
        method: 'post',
        url: '/sys_depart/add',
        data
    })
}
// 删除
export const sysDepartDelete = (data) => {
    return request({
        method: 'post',
        url: '/sys_depart/delete',
        data
    })
}
// 编辑
export const sysDepartUpdate = (data) => {
    return request({
        method: 'post',
        url: '/sys_depart/update',
        data
    })
}
// 编辑用户
export const sysDepartUpdateDepartUser = (data) => {
    return request({
        method: 'post',
        url: '/sys_depart/update-depart-user',
        data
    })
}
