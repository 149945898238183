<template>
  <el-card id="realReport">
    <header>
      <h2 style="display: flex; align-items: center;margin-bottom: 10px;">
        实时报表
        <span v-if="roleName == '企业' && showTitleText">(可按时间段筛选，也可以按日、周、月报表来查看：自己以及部门所有坐席的通话情况、接通情况、平均通话时长等)</span>
        <span v-if="roleName == '组长' && showTitleText">(可按时间段筛选，也可以按日、周、月报表来查看：自己以及部门所有坐席的通话情况、接通情况、平均通话时长等)</span>
        <span v-if="roleName == '人员' && showTitleText">(可按时间段筛选，也可以按日、周、月报表来查看：员工自己的通话情况、接通情况、平均通话时长等)</span>
        <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
          <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
            style="width: 30px; height: 30px; cursor: pointer" @click="showTitleText = true" />
        </el-tooltip>
      </h2>
      <el-alert title="提示：每次报表更新周期为五分钟。请选择日/周/月报表查询，或者自定义查询。查看当天报表请使用日报表查询，其它查询不包含当天报表。" type="warning" effect="dark">
      </el-alert>
      <div class="search">
        <div class="left">
          <div class="left-wrap" style="margin-right: 10px" v-if="role == '客服'">
            <span>查询公司</span>
            <el-select v-model="firmValue" placeholder="选择查询公司" size="mini" filterable @change="getFirmVal">
              <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="left-wrap" style="margin-right: 10px" v-if="true">
            <span>选择部门</span>
            <el-select v-model="departId" placeholder="请选择部门" filterable size="mini">
                  <el-option v-for="item in underDepart" :key="item.id" :label="item.departName" :value="item.id">
                  </el-option>
                </el-select>
          </div> -->
          <div class="left-wrap">
            <span>自定义查询时间</span>
            <el-date-picker v-model="customTime" type="daterange" range-separator="-" start-placeholder="开始时间"
              end-placeholder="结束时间" @calendar-change="calendar" unlink-panels :disabled-date="disableddate" size="mini"
              :picker-options="pickerOptions"></el-date-picker>
          </div>

          <el-button type="primary" icon="el-icon-search" style="margin-left: 10px" @click="resetCustomTimeSearch"
            size="mini">自定义查询</el-button>
          <el-button type="primary" icon="el-icon-search" @click="reset()" size="mini">重置</el-button>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="day" size="mini" class="searchBtn">日报表</el-button>
          <el-button type="primary" icon="el-icon-search" @click="week" size="mini" class="reset">周报表</el-button>
          <el-button type="primary" icon="el-icon-search" @click="resetMonth" size="mini" class="reset">月报表</el-button>
          <el-button type="primary" icon="el-icon-folder-opened" @click="excel" size="mini" class="reset"
            v-if="roleName == '企业'">导出</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main v-loading="loading" element-loading-text="请稍等，数据加载中……">
      <div class="time-report">
        <span>{{ tableData[0].day }}</span>
      </div>
      <el-table :data="tableData" class="table" ref="multipleTable" height="500" header-cell-class-name="table-header"
        show-summary>
        <el-table-column prop="name" label="员工名称" align="center"></el-table-column>
        <el-table-column prop="departName" label="部门名称" align="center"></el-table-column>
        <el-table-column prop="sta" label="通话时间" align="center"></el-table-column>
        <el-table-column prop="seconds" label="累加通话秒数" sortable align="center"></el-table-column>
        <el-table-column prop="minute" label="累加通话分钟数" sortable align="center"></el-table-column>
        <el-table-column prop="moneyMinute" label="计费分钟数" sortable align="center"></el-table-column>
        <el-table-column prop="sumCount" label="外呼数" sortable align="center"></el-table-column>
        <el-table-column prop="count" label="接通次数" sortable align="center"></el-table-column>
        <el-table-column prop="count_sumCount" label="接通率" sortable align="center"></el-table-column>
        <el-table-column prop="avg" label="平均通话时长" sortable align="center"></el-table-column>
      </el-table>
    </main>
    <!--分页  -->
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
        :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
        @current-change="handlePageChange"></el-pagination>
    </div>
    <!-- :visible.sync -->
  </el-card>
</template>

<script>
import axios from "axios";

import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  tableCountTable,
  daySaveList,
  tableWeektableAll,
  tableMonthtableAll,
  tableDaytableAll,
} from "@/api/table";
import { sysDepartList } from "@/api/sys_depart";
import { userOperatorsFindlist } from "@/api/user";
export default {
  data() {
    return {
      currentQuery: null,
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      showTitleText: false,
      roleName: null,
      firmVal: null,
      firmValue: null,
      firmList: [],
      role: null,
      value: "",
      customTime: null,
      tableData: [{ day: "", name: "还未开始查询" }],
      tableDataB: [{ day: "" }],
      realname: null,
      id: null,
      loading: false,
      pageTotal: 0, //总数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.id = localStorage.getItem("id");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.role = localStorage.getItem("role");
    // this.day();
    if (this.role == "客服") {
      this.operatorsList();
    }
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  },
  methods: {
    // 分页查询
    handlePageChange(val) {
      this.page = val;
      if (this.currentQuery == '月报表') {
        this.month('月报表')
      } else if (this.currentQuery == '自定义查询') {
        this.customTimeSearch('自定义查询')
      }
    },
    handleSizeChange(val) {
      this.size = val;
      if (this.currentQuery == '月报表') {
        this.month('月报表')
      } else if (this.currentQuery == '自定义查询') {
        this.customTimeSearch('自定义查询')
      }
    },
    //导出
    excel() {
      if ([...this.tableData] == this.tableDataB) {
        Message.error("表单数据为空!");
        return;
      }
      let params = {
        // sta: this.sta1,
        // end: this.end1,
        exportList: this.tableData,
      };
      console.log(params);
      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("table/export-table", params, { responseType: "blob" })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.style.display = "none";
          let str = res.headers["content-disposition"];
          console.log("str", res);
          // a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);
          a.download = "table.xls";
          a.href = url;
          a.click();
        });
    },
    // 获取下拉框选中值
    getFirmVal(val) {
      this.firmVal = val;
      this.day1();
    },
    // 客服查公司
    operatorsList() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res = userOperatorsFindlist(params);
      res.then((res) => {
        this.firmList = res.data.data;
      });
    },
    // 重置
    reset() {
      this.firmValue = null;
      this.customTime = null;
      this.page = 1
      this.size = 10
      this.day();
      if (this.role == "客服") {
        this.operatorsList();
      }
      // this.firmValue = null;
    },
    disableddate(time) {
      return (
        time.getTime() > Date.now() - 1000 * 60 * 60 * 24 * 0 ||
        time.getTime() < Date.now() - 1000 * 60 * 60 * 24 * 45
      );
    },
    calendar(arr) {
      this.value = arr[0];
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    getDate(date) {
      this.form.day = dayjs(date).format("YYYY-MM-DD");
    },
    resetCustomTimeSearch() {
      this.page = 1
      this.customTimeSearch('自定义查询')
    },
    customTimeSearch(str) {
      this.currentQuery = str
      let searchTime = localStorage.getItem("searchTime") * 1;
      if (searchTime) {
        let timesZdy1 = Date.parse(new Date());
        if (timesZdy1 - searchTime >= 10000) {
          this.customTimeSearch1();
          Message.success("10秒后可再次获取");
          let timesZdy = Date.parse(new Date());
          localStorage.setItem("searchTime", timesZdy);
        } else {
          this.loading = false;
          Message.warning("时间未满10秒，请稍等");
        }
      } else {
        this.customTimeSearch1();
        Message.success("10秒后可再次获取");
        let timesZdy = Date.parse(new Date());
        localStorage.setItem("searchTime", timesZdy);
      }
    },
    customTimeSearch1() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      console.log("获取时间", this.customTime);
      let sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.customTime[0]);
      let end = this.getYMDHMS(
        "YYYY-mm-dd",
        this.customTime[1]
      );
      end += " 23:59:59"
      let params = {
        userId: this.id,
        page: this.page,
        size: this.size,
        sta,
        end,
      };
      if (this.firmVal) {
        params.userId = this.firmVal;
      }

      // 当账号为前台冉璐的账号时
      if (this.id == '8036') {
        params.userId = '7'
      }

      let res = daySaveList(params);
      res.then((res) => {
        if (res.data.statusCode == '50000') {
          this.loading = false;
          // Message.warning(res.data.message);
        } else {
          this.tableData = [];
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }

        console.log("res", res.data.data);
      });
    },
    // 时间限制-今
    day() {
      let dayTime = localStorage.getItem("dayTime") * 1;
      if (dayTime) {
        let timesDay1 = Date.parse(new Date());
        if (timesDay1 - dayTime >= 10000) {
          this.day1();
          Message.warning("10秒后可再次获取");
          let timesDay = Date.parse(new Date());
          localStorage.setItem("dayTime", timesDay);
        } else {
          Message.warning("时间未满10秒，请稍等");
        }
      } else {
        this.day1();
        Message.warning("10秒后可再次获取");
        let timesDay = Date.parse(new Date());
        localStorage.setItem("dayTime", timesDay);
      }
    },
    // 今
    async day1() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
        };
      } else {
        params = {
          userId: this.id,
        };
      }
      let res = await tableDaytableAll(params);

      if (res.data.statusCode == '50000') {
        this.loading = false;
        // Message.warning(res.data.message);
      } else {
        this.tableData = [];
        this.tableData = res.data.data;
        this.pageTotal = this.tableData.length;

      }
    },
    // 时间限制-周
    week() {
      let weekTime = localStorage.getItem("weekTime") * 1;
      if (weekTime) {
        let timesWeek1 = Date.parse(new Date());
        if (timesWeek1 - weekTime >= 30000) {
          this.week1();
          Message.warning("30秒后可再次获取");
          let timesWeek = Date.parse(new Date());
          localStorage.setItem("weekTime", timesWeek);
        } else {
          Message.warning("时间未满30秒，请稍等");
        }
      } else {
        this.week1();
        Message.warning("30秒后可再次获取");
        let timesWeek = Date.parse(new Date());
        localStorage.setItem("weekTime", timesWeek);
      }
    },
    // 周
    week1() {
      this.loading = true;
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
        };
      } else {
        params = {
          userId: this.id,
        };
      }
      let res = tableWeektableAll(params);
      res.then((res) => {
        if (res.data.statusCode == '50000') {
          this.loading = false;
          // Message.warning(res.data.message);
        } else {
          this.tableData = [];
          this.tableData = res.data.data;
        }
      });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    resetMonth() {
      this.page = 1
      this.month('月报表')
    },
    // 时间限制-月
    month(str) {
      this.currentQuery = str
      let monthTime = localStorage.getItem("monthTime") * 1;
      if (monthTime) {
        let timesMonth1 = Date.parse(new Date());
        if (timesMonth1 - monthTime >= 10000) {
          this.month1();
          Message.warning("10秒后可再次获取");
          let timesMonth = Date.parse(new Date());
          localStorage.setItem("monthTime", timesMonth);
        } else {
          Message.warning("时间未满10秒，请稍等");
        }
      } else {
        this.month1();
        Message.warning("10秒后可再次获取");
        let timesMonth = Date.parse(new Date());
        localStorage.setItem("monthTime", timesMonth);
      }
    },
    // 获取当前或几天前的时间
    GetDateStr(AddDayCount) {

      var dd = new Date();

      dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期

      var y = dd.getFullYear();

      var m = dd.getMonth() + 1;//获取当前月份的日期

      var d = dd.getDate();

      return y + "-" + m + "-" + d;

    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      return year + "-" + month + "-" + day;
    },
    // 月
    month1() {
      this.loading = true;
      // 获取当前日期对象
      var currentDate = new Date();

      // 获取当前月份的第一天
      var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      // 获取当前月份的下个月的第一天
      var nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

      // 获取当前月份的最后一天
      var lastDay = new Date(nextMonth.getTime() - 1);

      // 格式化日期为字符串
      let sta = this.formatDate(firstDay); // 格式化第一天
      let end = this.formatDate(lastDay); // 格式化最后一天
      // let sta = this.getYMDHMS("YYYY-mm-dd", this.GetDateStr(-31));
      // let end = this.getYMDHMS("YYYY-mm-dd", this.GetDateStr(-1));
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
          page: this.page,
          size: this.size,
          sta,
          end,
        };
      } else {
        params = {
          userId: this.id,
          page: this.page,
          size: this.size,
          sta,
          end,
        };
      }

      let res = daySaveList(params);
      res.then((res) => {
        if (res.data.statusCode == '50000') {
          this.loading = false;
          // Message.warning(res.data.message);
        } else {
          this.tableData = [];
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }

      });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    getDepart() {
      let params = {
        topId: this.id,
      };
      let res = sysDepartList(params);
      res.then((res) => {
        this.options = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#realReport {
  height: 100%;
  display: flex;
  flex-direction: column;
  // height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;

      // flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {}

  .pagination {
    // position: fixed;
    width: 100%;
    // height: 40px;
    // border: 1px solid red;
    background-color: #fff;
    // bottom: 0;
    // right: 0;
    // z-index: 99;
    text-align: right;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    padding: 10px 0;
    box-sizing: border-box;
    // margin: 20px 0;
  }
}
</style>