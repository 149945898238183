/**
 * table
 * 报表
 */

import request from "@/utils/request";

// 个人通话数据
export const tableUserSelectShell = (data) => {
  return request({
    method: "post",
    url: "/table/userSelectShell",
    data,
  });
};

// 企业历史消费(双钱包)
export const monthtablelTable = (data) => {
  return request({
    method: "post",
    url: "/table/money-monthtable",
    data,
  });
};

// 公司所有人通话记录
// url: '/table/selectShell',
export const tableSelectShell = (data) => {
  return request({
    method: "post",
    url: "/table/selectShell2",
    data,
  });
};
// 日
export const tableDay = (data) => {
  return request({
    method: "post",
    url: "/table/day",
    data,
  });
};
// 月
export const tableMonth = (data) => {
  return request({
    method: "post",
    url: "/table/month",
    data,
  });
};
// 新月报表查询及自定义查询
export const daySaveList = (data) => {
  return request({
    method: "post",
    url: "/table/day-save-list",
    data,
  });
};
//
export const tableCountTable = (data) => {
  return request({
    method: "post",
    url: "/table/count-table",
    data,
  });
};
//
export const tableDaytableAll = (data) => {
  return request({
    method: "post",
    url: "/table/daytableAll",
    // url: "/table/daytableAll2",
    data,
  });
};
//
export const tableWeektableAll = (data) => {
  return request({
    method: "post",
    url: "/table/weektableAll",
    data,
  });
};
//
export const tableMonthtableAll = (data) => {
  return request({
    method: "post",
    url: "/table/monthtableAll",
    data,
  });
};

// 今周月查询
export const tableTimeAll = (url, data) => {
  return request({
    method: "post",
    url: "/table/" + url,
    data,
  });
};
